import axios from "axios";
import { logout } from "../actions";
import { store } from "index";
import { URLS } from "../constants";
import * as notifications from "../notifications";

/* Get the API server url.
 * Assumes react development server will be running in localhost
 * and django server in port 8000 */
export const getServerUrl = () => {
  let origin = document.location.origin;
  if (origin.includes("localhost")) {
    let localServerUrl = new URL(origin);
    localServerUrl.port = "8000";
    return localServerUrl.toString();
  }

  origin = origin.replace("s3-website-us-west-2.amazonaws.com", "");
  if (origin.includes("dev.liferocanalytics.com")) {
    origin = origin.replace("dev.liferocanalytics.com", "dev-api.liferocanalytics.com");
  }
  return origin;
};

let client = axios.create({
  headers: {
    /* Use toString to look at the token each time the request is made.
     * Otherwise, when first loading the page and no token is yet present,
     * no token will be set and axios will send requests with
     * "JWT null" until the page is refreshed */
    Authorization: {
      toString() {
        return `JWT ${localStorage.getItem("ahq_jwt_token")}`;
      },
    },
  },
  baseURL: getServerUrl(),
});

client.interceptors.response.use(
  function (response) {
    if (response.data.refresh_token) {
      localStorage.setItem("ahq_jwt_token", response.data.refresh_token);
      delete response.data.refresh_token;
    }
    return response;
  },
  function (error) {
    const isAuthURLs =
      window.location.pathname === URLS.RESET_PASSWORD ||
      window.location.pathname.includes(URLS.LOGIN) ||
      window.location.pathname.indexOf(URLS.SET_PASSWORD) !== -1;
    if (
      error.response.status === 401 &&
      !isAuthURLs &&
      (error.response.data.detail === "Signature has expired." || error.response.data.code === "token_not_valid")
    ) {
      const requestedUrl = typeof window.location.pathname === "string" ? window.location.pathname : "";
      const redirectUrl = requestedUrl ? URLS.LOGIN + "next=" + requestedUrl : URLS.LOGIN;
      store.dispatch(logout(redirectUrl));
    }
    if (error.response.status === 404) {
      // dont show the error for /life_settlement/api/doctor/ - this endpoint is expected to show 404 often
      if (!error.response.request.responseURL?.includes("/life_settlement/api/doctor/")) {
        notifications.error(error.response.data.detail);
      }
    }
    return Promise.reject(error);
  },
);

export default client;
