import advisorhq from "apis/advisorhq";
import {
  FETCH_ACCOUNT_CASES,
  CLEAN_ACCOUNT_CASES,
  FETCH_LIFE_SETTLEMENT_CASES,
  FETCH_TRADING_CASES,
  SET_CASES_PAGINATION_DATA,
  EDIT_LIFE_SETTLEMENT_CASE,
  CREATE_EMPTY_LIFE_SETTLEMENT_CASE,
  DELETE_LIFE_SETTLEMENT_CASE,
  FETCH_LIFE_SETTLEMENT_CASE,
  LOADING,
  STORE_CASES_FILTERS_DATA,
  CLEAN_LIFE_SETTLEMENT_CASE_DATA,
  CLEAR_CASES_DATA,
  CLEAR_ACTIVE_CASE_FILE,
  CLEAR_CASE_FILES,
  FETCH_LIFE_SETTLEMENT_CHOICES,
  FETCH_WINFLEX_CHOICES,
} from "../types.js";
import { sortByParam, parse_date_for_last_activity, redirectTo } from "../../utils.js";
import * as notifications from "notifications.js";
import {
  activeAgencySelector,
  pageSizeSelector,
  sortBySelector,
  activeCaseIdSelector,
  currentPageCaseSelector,
} from "reducers";

import { COPY_CASE_MODAL, PRICING_DOCUMENTS_MODAL } from "components/modals";
import { hideModal } from "actions";
import * as types from "../types";
import { calcRevenueModelValue, filterMarketActivities } from "../../pages/cases/auction/form/PlacementRevenueValue";
import {
  getHighestFunder,
  calcFunderHighestOffer,
  calcMarketHighestOffer,
} from "../../pages/cases/auction/form/CalcHighestOffer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get_websocket_url } from "./utils";
import { calcLastActivity } from "../../pages/cases/auction/form/CalcLastActivity";

/* Fetch life settlement cases using the given filters. Additionally, filter by
 * the active agency: users can only see cases for the agencies they have enabled.
 */

export const fetchTradingCases = filters => async (dispatch, getState) => {
  const activeAgency = activeAgencySelector(getState());
  let { stage, highest_funder, ...filters_param } = filters;
  const stages_dist = {
    Opportunity: "P-FB__T-NITR__T-VRF__T-PR__P-U",
    "In Closing": "IC-AOP__IC-ACG__IC-ACDR__IC-ACoCo__P-AFR__IC-ARE__IC-ACaCh",
    "Price Discovery": "P-AOA__P-A__PFA",
    Inactive: "I-RCNC__I-ICOBO__CBS__I-ICCNR__I-TDNP__I-TI__I-RNI__I-OD__I-ID__I-ICOR__I-ICPR__I-RS__PL__I-TCNI__OH",
    Asset: "F__CA__SA__LA__MA",
  };
  let parsed_stages = "";
  if (stage) {
    const stages_list = stage.split(",");
    for (const stagesListKey in stages_list) {
      const currentStage = stages_list[stagesListKey];
      if (stages_dist[currentStage]) {
        if (parsed_stages) {
          parsed_stages = parsed_stages + "__" + stages_dist[currentStage];
        } else {
          parsed_stages = stages_dist[currentStage];
        }
      }
    }
    if (parsed_stages) {
      filters_param = {
        stage__in: parsed_stages,
        ...filters_param,
      };
    }
  }

  const response = await advisorhq.get("/auctions/api/trading/", {
    params: {
      agency: activeAgency.id,
      ...filters_param,
    },
  });
  // calculated values and filtering by highest funder will be done in FE as DSL cannot
  // perform well with dynamic values (ex: switching agencies)
  let { results } = response.data;
  if (results) {
    for (let i = 0; i < results.length; i++) {
      const r = results[i];
      const a = r.auction;
      if (r && a.funderactivity_set.length > 0) {
        results[i].last_funder_activity = parse_date_for_last_activity(calcLastActivity(a.funderactivity_set).date);
        let highest = getHighestFunder(a.funderactivity_set);
        results[i].highest_funder_name = (highest && highest.funder_name) || "";
        results[i].highest_funder_id = (highest && highest.funder) || "";
      } else {
        results[i].highest_funder_name = "";
      }
      if (r && a.funderactivity_set.length > 0) {
        results[i].highest_funder_offer = calcFunderHighestOffer(a.funderactivity_set, true);
      } else {
        results[i].highest_funder_offer = 0;
      }
      if (r && a.marketactivity_set.length > 0) {
        results[i].last_market_activity = parse_date_for_last_activity(calcLastActivity(a.marketactivity_set).date);
        results[i].our_revenue = calcRevenueModelValue(
          a.marketactivity_set,
          a.funderactivity_set,
          activeAgency.id,
          true,
        );
        results[i].highest_market_offer = calcMarketHighestOffer(a.marketactivity_set, true);
      } else {
        results[i].our_revenue = 0;
        results[i].highest_market_offer = 0;
      }
      if (
        r &&
        a.marketactivity_set.length > 0 &&
        // this function returns a Market Acitvity Array based on a MA Array and prov
        // the function exists to filter by both Liferoc Providers if prov is Liferoc
        filterMarketActivities(a.marketactivity_set, activeAgency.id).length > 0
      ) {
        results[i].our_high_offer = calcMarketHighestOffer(
          // this function returns a Market Acitvity Array based on a MA Array and prov
          // the function exists to filter by both Liferoc Providers if prov is Liferoc
          filterMarketActivities(a.marketactivity_set, activeAgency.id),
          true,
        );
      } else {
        results[i].our_high_offer = 0;
      }
    }
  }
  if (highest_funder) {
    results = results.filter(f => f.highest_funder_name === highest_funder);
  }
  dispatch({ type: FETCH_TRADING_CASES, payload: results });
};

export const cleanAccountCases = () => async dispatch => {
  dispatch({ type: CLEAN_ACCOUNT_CASES });
};

export const fetchAccountCases = (filters, orderBy) => async (dispatch, getState) => {
  const activeAgency = activeAgencySelector(getState());
  dispatch({ type: LOADING, payload: { cases: true } });
  const response = await advisorhq.get("/life_settlement/api/cases/v2/", {
    params: {
      agency: activeAgency.id,
      ...filters,
    },
  });
  dispatch({ type: FETCH_ACCOUNT_CASES, payload: response.data });
  dispatch({ type: LOADING, payload: { cases: false } });
};

export const fetchLifeSettlementCases = (filters, orderBy) => async (dispatch, getState) => {
  const activeAgency = activeAgencySelector(getState());
  const pageSize = pageSizeSelector(getState());
  dispatch({ type: LOADING, payload: { cases: true } });
  const sort_by_valid_values = [
    "carrier_name",
    "sales_representative",
    "source",
    "insured_names",
    "face_amount",
    "product_type",
    "source_name",
    "sales_representative_name",
    "status_name",
    "submission_date",
    "jurisdiction",
    "id",
    "next_due_date",
  ];
  const sort_by = sortBySelector(getState());
  // validates if the received sort_by parameter matches the list, otherwise sets default
  var sort_by_value = sortByParam(sort_by);
  if (sort_by.id.length > 0) {
    if (sort_by_valid_values.includes(sort_by.id)) {
    } else {
      sort_by_value = "-submission_date";
    }
  }
  const page = currentPageCaseSelector(getState()) || 1;
  const response = await advisorhq.get("/life_settlement/api/cases/v2/", {
    params: {
      agency: activeAgency.id,
      page: page,
      page_size: pageSize,
      ...filters,
      sort_by: sort_by_value,
    },
  });
  let { results, ...paginateData } = response.data;
  paginateData = { ...paginateData, page };
  dispatch({ type: FETCH_LIFE_SETTLEMENT_CASES, payload: results });
  dispatch({ type: SET_CASES_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: LOADING, payload: { cases: false } });
};

export const editCase =
  (id, newValues, update = true) =>
  async dispatch => {
    let editedValues = newValues;
    const url = `/life_settlement/api/cases/v2/${id}/`;
    const response = await advisorhq.patch(url, editedValues);
    // if update isn't passed as "false", then dispatch with the results
    if (update) {
      dispatch({ type: EDIT_LIFE_SETTLEMENT_CASE, payload: response.data });
    }
    return response;
  };

export const editCaseHierarchy = (id, values) => async dispatch => {
  const url = `/life_settlement/api/case_hierarchy/${id}/`;
  const response = await advisorhq.patch(url, values);
  dispatch({ type: EDIT_LIFE_SETTLEMENT_CASE, payload: response.data });
  notifications.success("Case Hierarchy saved successfully!");
  return response;
};

export const editCaseWithoutDispatch = (id, newValues) => async dispatch => {
  const url = `/life_settlement/api/cases/v2/${id}/`;
  await advisorhq.patch(url, newValues);
};

export const copyToOtherAgency = (id, agency_id) => async (dispatch, getState) => {
  // this also calls the copy_insured endpoint to get the task id
  // there is no need to create a new endpoint
  let url = `/life_settlement/api/get-websocket-params/${id}/`;
  const activeAgency = activeAgencySelector(getState());
  dispatch({ type: CLEAN_LIFE_SETTLEMENT_CASE_DATA });
  const params = {
    agency: activeAgency.id,
  };
  const response = await advisorhq.post(url, params);
  dispatch(hideModal(COPY_CASE_MODAL));
  url = get_websocket_url(response, "other-agency", agency_id);
  const chatSocket = new WebSocket(url);
  const toastId = toast("Copying case: Copy to Other Agency", notifications.successProgressConfig);
  toast.update(toastId, { progress: 0 / 1 });
  chatSocket.onmessage = function (e) {
    let data = JSON.parse(e.data);
    if (data.type === "connection_established" || data.type === "progress") {
      toast.update(toastId, {
        render: `${data.progress}/6: ${data.message}`,
        progress: Number(data.progress) / 6,
      });
    } else {
      toast.done(toastId);
    }
  };
};

export const copyCase = id => async (dispatch, getState) => {
  // this also calls the copy_insured endpoint to get the task id
  // there is no need to create a new endpoint
  let url = `/life_settlement/api/get-websocket-params/${id}/`;
  const activeAgency = activeAgencySelector(getState());
  dispatch({ type: CLEAN_LIFE_SETTLEMENT_CASE_DATA });
  const params = {
    agency: activeAgency.id,
  };
  const response = await advisorhq.post(url, params);
  dispatch(hideModal(COPY_CASE_MODAL));
  url = get_websocket_url(response, "case");
  const chatSocket = new WebSocket(url);
  const toastId = toast("Copying case: Case", notifications.successProgressConfig);
  toast.update(toastId, { progress: 0 / 1 });
  chatSocket.onmessage = function (e) {
    let data = JSON.parse(e.data);
    if (data.type === "connection_established" || data.type === "progress") {
      toast.update(toastId, {
        render: `${data.progress}/6: ${data.message}`,
        progress: Number(data.progress) / 6,
      });
    } else {
      toast.done(toastId);
    }
  };
};

export const copyInsured = id => async (dispatch, getState) => {
  let url = `/life_settlement/api/get-websocket-params/${id}/`;
  const activeAgency = activeAgencySelector(getState());
  dispatch({ type: CLEAN_LIFE_SETTLEMENT_CASE_DATA });
  const params = {
    agency: activeAgency.id,
  };
  const response = await advisorhq.post(url, params);
  dispatch(hideModal(COPY_CASE_MODAL));
  url = get_websocket_url(response, "copy-insured");
  const chatSocket = new WebSocket(url);
  const toastId = toast("Copying case: insured", notifications.successProgressConfig);
  toast.update(toastId, { progress: 0 });
  chatSocket.onmessage = function (e) {
    let data = JSON.parse(e.data);
    if (data.type === "connection_established" || data.type === "progress") {
      toast.update(toastId, {
        render: `${data.progress}/4: ${data.message}`,
        progress: Number(data.progress) / 4,
      });
    } else {
      toast.done(toastId);
    }
  };
};

export const pricingAndDataTape = id => async (dispatch, getState) => {
  let url = `/life_settlement/api/get-websocket-params/${id}/`;
  const activeAgency = activeAgencySelector(getState());
  const params = {
    agency: activeAgency.id,
  };
  const response = await advisorhq.post(url, params);
  url = get_websocket_url(response, "pricing-and-data-tape");
  const chatSocket = new WebSocket(url);
  const toastId = toast("Creating pricing and Data Tape", notifications.successProgressConfig);
  toast.update(toastId, { progress: 1 / 99 });
  chatSocket.onmessage = async function (e) {
    let data = JSON.parse(e.data);
    if (data.type === "connection_established" || data.type === "progress") {
      toast.update(toastId, {
        render: `${data.progress}/14: ${data.message}`,
        progress: Number(data.progress) / 14,
      });
    } else if (data.type === "error") {
      toast.update(toastId, { type: "error", render: `${data.message}` });
    } else {
      toast.done(toastId);
      // re-fetch
      dispatch({ type: LOADING, payload: { case: true } });
      const caseResponse = await advisorhq.get(`/life_settlement/api/cases/v2/${id}/`);
      dispatch({ type: FETCH_LIFE_SETTLEMENT_CASE, payload: caseResponse.data });
      dispatch({ type: LOADING, payload: { case: false } });
    }
  };
};

export const pricingSetup = id => async (dispatch, getState) => {
  let url = `/life_settlement/api/get-websocket-params/${id}/`;
  const activeAgency = activeAgencySelector(getState());
  const params = {
    agency: activeAgency.id,
  };
  const response = await advisorhq.post(url, params);
  url = get_websocket_url(response, "pricing");
  const chatSocket = new WebSocket(url);
  const toastId = toast("Creating pricing setup", notifications.successProgressConfig);
  toast.update(toastId, { progress: 1 / 99 });
  chatSocket.onmessage = async function (e) {
    let data = JSON.parse(e.data);
    if (data.type === "connection_established" || data.type === "progress") {
      toast.update(toastId, {
        render: `${data.progress}/8: ${data.message}`,
        progress: Number(data.progress) / 8,
      });
    } else if (data.type === "error") {
      toast.update(toastId, { type: "error", render: `${data.message}` });
    } else {
      toast.done(toastId);
      // re-fetch
      dispatch({ type: LOADING, payload: { case: true } });
      const caseResponse = await advisorhq.get(`/life_settlement/api/cases/v2/${id}/`);
      dispatch({ type: FETCH_LIFE_SETTLEMENT_CASE, payload: caseResponse.data });
      dispatch({ type: LOADING, payload: { case: false } });
    }
  };
};

export const pricingQcSetup = id => async (dispatch, getState) => {
  let url = `/life_settlement/api/get-websocket-params/${id}/`;
  const activeAgency = activeAgencySelector(getState());
  const params = {
    agency: activeAgency.id,
  };
  const response = await advisorhq.post(url, params);
  url = get_websocket_url(response, "pricing-qc");
  const chatSocket = new WebSocket(url);
  const toastId = toast("Creating Pricing Qc Checklist", notifications.successProgressConfig);
  toast.update(toastId, { progress: 1 / 99 });
  chatSocket.onmessage = async function (e) {
    let data = JSON.parse(e.data);
    if (data.type === "connection_established" || data.type === "progress") {
      toast.update(toastId, {
        render: `${data.progress}/8: ${data.message}`,
        progress: Number(data.progress) / 4,
      });
    } else if (data.type === "error") {
      toast.update(toastId, { type: "error", render: `${data.message}` });
    } else {
      toast.done(toastId);
      // re-fetch
      dispatch({ type: LOADING, payload: { case: true } });
      const caseResponse = await advisorhq.get(`/life_settlement/api/cases/v2/${id}/`);
      dispatch({ type: FETCH_LIFE_SETTLEMENT_CASE, payload: caseResponse.data });
      dispatch({ type: LOADING, payload: { case: false } });
    }
  };
};

export const baseXML = (id, mode) => async (dispatch, getState) => {
  let url = `/life_settlement/api/get-websocket-params/${id}/`;
  const activeAgency = activeAgencySelector(getState());
  const params = {
    agency: activeAgency.id,
  };
  const response = await advisorhq.post(url, params);
  url = get_websocket_url(response, mode);
  const chatSocket = new WebSocket(url);
  const toastId = toast("Requesting Xml file Creation", notifications.successProgressConfig);
  toast.update(toastId, { progress: 1 / 99 });
  chatSocket.onmessage = async function (e) {
    let data = JSON.parse(e.data);
    if (data.type === "connection_established" || data.type === "progress") {
      toast.update(toastId, {
        render: `${data.progress}/7: ${data.message}`,
        progress: Number(data.progress) / 7,
      });
    } else if (data.type === "error") {
      toast.update(toastId, { type: "error", render: `${data.message}` });
    } else {
      toast.done(toastId);
      // re-fetch
      dispatch({ type: LOADING, payload: { case: true } });
      const caseResponse = await advisorhq.get(`/life_settlement/api/cases/v2/${id}/`);
      dispatch({ type: FETCH_LIFE_SETTLEMENT_CASE, payload: caseResponse.data });
      dispatch({ type: LOADING, payload: { case: false } });
    }
  };
};

export const cgFile = id => async (dispatch, getState) => {
  let url = `/life_settlement/api/get-websocket-params/${id}/`;
  const activeAgency = activeAgencySelector(getState());
  const params = {
    agency: activeAgency.id,
  };
  const response = await advisorhq.post(url, params);
  url = get_websocket_url(response, "cg-file");
  const chatSocket = new WebSocket(url);
  const toastId = toast("Creating CG File", notifications.successProgressConfig);
  toast.update(toastId, { progress: 1 / 99 });
  chatSocket.onmessage = async function (e) {
    let data = JSON.parse(e.data);
    if (data.type === "connection_established" || data.type === "progress") {
      toast.update(toastId, {
        render: `${data.progress}/7: ${data.message}`,
        progress: Number(data.progress) / 7,
      });
    } else if (data.type === "error") {
      toast.update(toastId, { type: "error", render: `${data.message}` });
    } else {
      toast.done(toastId);
      // re-fetch
      dispatch({ type: LOADING, payload: { case: true } });
      const caseResponse = await advisorhq.get(`/life_settlement/api/cases/v2/${id}/`);
      dispatch({ type: FETCH_LIFE_SETTLEMENT_CASE, payload: caseResponse.data });
      dispatch({ type: LOADING, payload: { case: false } });
    }
  };
};

export const dataTape = id => async (dispatch, getState) => {
  let url = `/life_settlement/api/get-websocket-params/${id}/`;
  const activeAgency = activeAgencySelector(getState());
  const params = {
    agency: activeAgency.id,
  };
  const response = await advisorhq.post(url, params);
  url = get_websocket_url(response, "data-tape");
  const chatSocket = new WebSocket(url);
  const toastId = toast("Creating data tape", notifications.successProgressConfig);
  toast.update(toastId, { progress: 1 / 99 });
  chatSocket.onmessage = async function (e) {
    let data = JSON.parse(e.data);
    if (data.type === "connection_established" || data.type === "progress") {
      toast.update(toastId, {
        render: `${data.progress}/9: ${data.message}`,
        progress: Number(data.progress) / 9,
      });
    } else if (data.type === "error") {
      toast.update(toastId, { type: "error", render: `${data.message}` });
    } else {
      toast.done(toastId);
      // re-fetch
      dispatch({ type: LOADING, payload: { case: true } });
      const caseResponse = await advisorhq.get(`/life_settlement/api/cases/v2/${id}/`);
      dispatch({ type: FETCH_LIFE_SETTLEMENT_CASE, payload: caseResponse.data });
      dispatch({ type: LOADING, payload: { case: false } });
    }
  };
};

export const clearCaseFiles = () => async dispatch => {
  dispatch({ type: CLEAR_CASE_FILES });
};

export const clearActiveCaseFile = () => async dispatch => {
  dispatch({ type: CLEAR_ACTIVE_CASE_FILE });
};

export const clearCasesData = () => async dispatch => {
  dispatch({ type: CLEAR_CASES_DATA });
};

export const createEmptyCase =
  (redirect = true) =>
  async (dispatch, getState) => {
    const activeAgency = activeAgencySelector(getState());
    const url = "/life_settlement/api/create_empty_case/";
    dispatch({ type: CLEAN_LIFE_SETTLEMENT_CASE_DATA });
    const response = await advisorhq.post(url, { agency: activeAgency.id });
    dispatch({ type: CREATE_EMPTY_LIFE_SETTLEMENT_CASE, payload: response.data });
    notifications.success("A new life settlement case was created");
    if (redirect) {
      window.scrollTo(0, 0);
      redirectTo(`/cases/${response.data.id}/policy/`);
    }
    return response.data;
  };

export const deleteCase = id => async (dispatch, getState) => {
  const url = `/life_settlement/api/cases/v2/${id}/`;
  await advisorhq.delete(url);
  dispatch({ type: DELETE_LIFE_SETTLEMENT_CASE, payload: { id } });

  // re-fetch
  const activeAgency = activeAgencySelector(getState());
  dispatch({ type: LOADING, payload: { cases: true } });
  const caseResponse = await advisorhq.get("/life_settlement/api/cases/v2/", {
    params: {
      agency: activeAgency.id,
      sort_by: "-submission_date",
    },
  });
  const page = 1;
  let { results, ...paginateData } = caseResponse.data;
  paginateData = { ...paginateData, page };
  dispatch({ type: FETCH_LIFE_SETTLEMENT_CASES, payload: results });
  dispatch({ type: SET_CASES_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: LOADING, payload: { cases: false } });

  notifications.success("Case deleted successfully");
};

export const storeCasesFiltersData = filters => async (dispatch, getState) => {
  dispatch({ type: STORE_CASES_FILTERS_DATA, payload: filters });
};

export const sendCaseEmail = payload => async (dispatch, getState) => {
  const activeCaseId = activeCaseIdSelector(getState());
  const formData = new FormData();
  formData.append("url", payload.url);
  formData.append("to", payload.recipients);
  formData.append("subject", payload.subject);
  formData.append("body", payload.body);
  formData.append("bcc", payload.bcc);
  if (payload.files) {
    for (let i = 0; i < payload.files.length; i++) {
      formData.append("files[]", payload.files[i], payload.files[i].name);
    }
  }

  return await advisorhq.post(`/life_settlement/api/send_mail/` + activeCaseId + "/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const setShouldSave = () => async dispatch => {
  dispatch({ type: types.SHOULD_SAVE, payload: { shouldSave: true } });
};

export const unsetShouldSave = () => async dispatch => {
  dispatch({ type: types.SHOULD_SAVE, payload: { shouldSave: false } });
};

export const runWinFlexIllustration = values => async (dispatch, getState) => {
  const url = `/life_settlement/api/winflex_illustration/`;
  const response = await advisorhq.post(url, values);
  dispatch(hideModal(PRICING_DOCUMENTS_MODAL));
  notifications.success("WinFlex Illustration in process - we'll email you when it's ready");
  return response;
};

export const fetchWinFlexChoices = () => async (dispatch, getState) => {
  const url = `/life_settlement/api/winflex_choices/`;
  const response = await advisorhq.get(url);
  dispatch({ type: FETCH_WINFLEX_CHOICES, payload: response.data });
  return response;
};
