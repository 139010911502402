import React from "react";

import { Select } from ".";
import { usStatesChoices } from "options.js";
import SelectInlineLabel from "./selects/SelectInlineLabel";

export default ({ label = "State", name = "state", placeholder = "Select State", inline = true, ...props }) => {
  if (inline) {
    return (
      <SelectInlineLabel
        name={name}
        label={label}
        placeholder={placeholder}
        options={usStatesChoices.getChoices()}
        disabled={props.disabled}
        {...props}
      />
    );
  } else {
    return (
      <Select
        name={name}
        label={label}
        placeholder={placeholder}
        options={usStatesChoices.getChoices()}
        disabled={props.disabled}
        {...props}
      />
    );
  }
};
