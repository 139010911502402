import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import * as notifications from "notifications";
import { Select, FormCollapsible, SubmitButton, TextInput } from "components/forms";
import {
  copyCase,
  copyInsured,
  createEmptyCase,
  dataTape,
  baseXML,
  cgFile,
  pricingAndDataTape,
  pricingSetup,
  fetchInsured,
  runWinFlexIllustration,
  fetchWinFlexChoices,
} from "actions";
import {
  activeCaseIdSelector,
  activeCaseSelector,
  carriersSelector,
  insuredListSelector,
  policySelector,
} from "reducers";
import { makeChoices } from "../../funders/utils";

const DOCUMENT_OPTIONS = [
  "Pricing",
  "Pricing QC Checklist",
  "LifeRoc Data Tape",
  "Pricing & LifeRoc Data Tape",
  "CG Data Import",
  "Ress XML",
  "SL XML",
  "BroadRiver XML",
];

const PricingDocumentForm = ({ id, ...props }) => {
  const formikRef = useRef();

  React.useEffect(() => {
    // Required for WinFlex details
    props.fetchInsured(props.caseInfo.id);
    props.fetchWinFlexChoices();
  }, [props.caseInfo?.id]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        show_error: "Data tape already exists; data may be lost.",
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        delete values.show_errors;
        if (values.action) {
          try {
            switch (values.action) {
              case DOCUMENT_OPTIONS[0]:
                await props.pricingSetup(id);
                break;
              case DOCUMENT_OPTIONS[1]:
                await props.pricingSetup(id);
                break;
              case DOCUMENT_OPTIONS[2]:
                await props.dataTape(id);
                break;
              case DOCUMENT_OPTIONS[3]:
                await props.pricingAndDataTape(id);
                break;
              case DOCUMENT_OPTIONS[4]:
                await props.cgFile(id);
                break;
              case DOCUMENT_OPTIONS[5]:
                await props.baseXML(id, "ress-xml");
                break;
              case DOCUMENT_OPTIONS[6]:
                await props.baseXML(id, "sl-xml");
                break;
              case DOCUMENT_OPTIONS[7]:
                await props.baseXML(id, "broadriver-xml");
                break;
            }
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Error generating document");
          }
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Generate Pricing Documents">
            <Form.Row>
              <Select
                label="Selected Document"
                name="action"
                placeholder="Select a Document"
                options={props.documentChoices}
                expandMenu={true}
              />
            </Form.Row>
            {[DOCUMENT_OPTIONS[2], DOCUMENT_OPTIONS[3]].includes(values.action) && (
              <Form.Row>
                <TextInput label="Warning" name="show_error" disabled />
              </Form.Row>
            )}
            <SubmitButton
              defaultText={"Generate"}
              disabled={!values.action}
              style={{
                width: "85%",
                marginBottom: "1rem",
              }}
            />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const documentChoices = makeChoices(DOCUMENT_OPTIONS);
  return {
    caseInfo: activeCaseSelector(state),
    activeCaseId: activeCaseIdSelector(state),
    carriers: carriersSelector(state),
    policyInfo: policySelector(state),
    insuredInfo: insuredListSelector(state)[0],
    documentChoices,
  };
};

export default connect(mapStateToProps, {
  copyCase,
  copyInsured,
  createEmptyCase,
  pricingSetup,
  dataTape,
  cgFile,
  baseXML,
  runWinFlexIllustration,
  pricingAndDataTape,
  fetchInsured,
  fetchWinFlexChoices,
})(PricingDocumentForm);
